const sliderStyle = {
  position: 'relative',
  width: '100%',
  height: 80,
}
  
const railStyle = {
  position: 'absolute',
  width: '100%',
  height: 10,
  marginTop: 35,
  borderRadius: 5,
  backgroundColor: '#2DA36B',
}

export { railStyle, sliderStyle }
